import React from "react"
import * as styles from './styles'
import { Insight } from "graphql/types"
import { Button } from "@material-ui/core"

type Props = {
    insight: Insight
}
export const DetailsInfo = ({insight}:Props) => {  
    return (
        <div className={styles.info}>
            <div>
                <div>
                    <strong>{new Date(insight.GeneratedAt).toLocaleString()}</strong>
                </div>
                <div>
                    {insight.Summary?.ProviderName}
                </div>
                {insight.Error && (
                    <div style={{backgroundColor: "#FFCCCB", border: "1px solid #333", padding: "5px", margin: "5px 0"}}>
                        {insight.Error}
                    </div>
                )}
                <div>
                    <strong>InsightID: </strong>{insight.InsightID}
                </div>
                <div>
                    <strong>BillID: </strong>{insight.BillId}
                </div>
                {insight.Summary && (
                    <div>
                        <strong>Source: </strong>{insight.Summary.Source}
                    </div>
                )}
                <Button
                    style={{border: "1px solid #333", padding: "5px 15px", margin: "5px 0"}}
                    href={`/inspectResult?insightID=${insight.InsightID}&userID=${insight.UserID}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    SHOW RESULT
                </Button>
                {insight.Error !== "" && (
                    <Button
                        style={{border: "1px solid #333", padding: "5px 15px", margin: "5px 0"}}
                        href={`/inspectResult?insightID=${insight.InsightID}&userID=${insight.UserID}&extractView=1`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        VIEW EXTRACT
                    </Button>
                )}
            </div>
        </div>
    )
}